exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-ben-digital-brand-js": () => import("./../../../src/pages/portfolio/ben-digital-brand.js" /* webpackChunkName: "component---src-pages-portfolio-ben-digital-brand-js" */),
  "component---src-pages-portfolio-ben-internet-banking-js": () => import("./../../../src/pages/portfolio/ben-internet-banking.js" /* webpackChunkName: "component---src-pages-portfolio-ben-internet-banking-js" */),
  "component---src-pages-portfolio-design-practice-js": () => import("./../../../src/pages/portfolio/design-practice.js" /* webpackChunkName: "component---src-pages-portfolio-design-practice-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-product-strategy-js": () => import("./../../../src/pages/portfolio/product-strategy.js" /* webpackChunkName: "component---src-pages-portfolio-product-strategy-js" */),
  "component---src-pages-portfolio-pursuit-js": () => import("./../../../src/pages/portfolio/pursuit.js" /* webpackChunkName: "component---src-pages-portfolio-pursuit-js" */),
  "component---src-pages-portfolio-unimelb-design-js": () => import("./../../../src/pages/portfolio/unimelb-design.js" /* webpackChunkName: "component---src-pages-portfolio-unimelb-design-js" */),
  "component---src-pages-portfolio-up-foundations-js": () => import("./../../../src/pages/portfolio/up-foundations.js" /* webpackChunkName: "component---src-pages-portfolio-up-foundations-js" */)
}

